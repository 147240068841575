import "./globals.css"
import { Button } from "./components/ui/button"
import {Fragment, useEffect, useState, useCallback} from "react";
import Step from "./components/step";
import Month from "./components/month";
import Time from "./components/time";
import RequestForm from "./components/requestForm";
import Spinner from "./components/spinner";

function App(props: any) {

    const properties = JSON.parse(props.bookingRoot.dataset.properties);

    const currentDate = new Date();

    const [step, setStep] = useState<number>(1);

    const [slotObjects, setSlotObjects] = useState<[]>([]);
    const [slotObject, setSlotObject] = useState<string|boolean>(false);
    const [availableSlots, setAvailableSlots] = useState<[]>([]);

    const [selectedDate, setSelectedDate] = useState<[]|any>(false);
    const [selectedSlot, setSelectedSlot] = useState<[]|boolean>(false);

    const [selectedDates, setSelectedDates] = useState<[]|any>([]);
    const [selectedSlots, setSelectedSlots] = useState<[]>([]);

    const [currentYear, setCurrentYear] = useState<number>((currentDate.getFullYear()));
    const [currentMonth, setCurrentMonth] = useState<number>((currentDate.getMonth() + 1));

    const [allDay, setAllDay] = useState<boolean>(false);
    const [allowMultiple, setAllowMultiple] = useState<boolean>(false);

    const [submitted, setSubmitted] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const fetchSlotObjects = () => {

        fetch(`${process.env.REACT_APP_API_URL}/api/booking/slotobject/index`, {method: 'GET', redirect: 'follow'})
            .then((response) => response.json())
            .then((result) => {
                // console.log(result)
                if(properties && properties.restrictToSlotObjects && properties.restrictToSlotObjects.length > 0) {
                    const restrictToSlotObjects = properties.restrictToSlotObjects;
                    const filteredSlotObjects = result.filter((item: {identifier: string}) => restrictToSlotObjects.includes(item.identifier) );
                    setSlotObjects(filteredSlotObjects);
                } else {
                    setSlotObjects(result);
                }
            })
            .catch((error) => console.error(error));
    };

    const fetchAvailableSlots = useCallback((selectedYear: number, selectedMonth: number) => {

        fetch(`${process.env.REACT_APP_API_URL}/api/booking/frontend/${selectedYear}/${selectedMonth}/${slotObject}`, {method: 'GET', redirect: 'follow'})
            .then((response) => response.json())
            .then((result) => {
                // console.log(result)
                setAvailableSlots(result);
            })
            .catch((error) => console.error(error));
    }, [slotObject]);

    const resetValues = () => {
        setSelectedDate(false);
        setSelectedSlot(false);
    }

    const handleSetSelectedDates = (item: any) => {
        const currentSelectedDatesString = JSON.stringify(selectedDates);
        const currentSelectedDates = JSON.parse(currentSelectedDatesString);

        const isInSelectedDates = (item: any) => {
            if(currentSelectedDates && currentSelectedDates.length > 0) {
                for(let i in selectedDates) {
                    const string = JSON.stringify(selectedDates[i]);
                    if(string === JSON.stringify(item)) {
                        return true;
                    }
                }
            }
            return false;
        }

        if (currentSelectedDates && isInSelectedDates(item)) {
            const newItems = currentSelectedDates.filter((el: any) => el.date !== item.date);
            setSelectedDates(newItems);
        } else {
            const newItems = [...currentSelectedDates, item];
            setSelectedDates(newItems);
        }


        // if (currentSelectedDates && currentSelectedDates.includes(item)) {
        //     const newItems = currentSelectedDates.filter((el: any) => el !== item);
        //     setSelectedDates(newItems);
        // } else {
        //     const newItems = [...currentSelectedDates, item];
        //     setSelectedDates(newItems);
        // }
    }

    useEffect(() => {
        fetchSlotObjects();
    }, []);

    useEffect(() => {
        fetchAvailableSlots(currentYear, currentMonth);
    }, [slotObject, currentYear, currentMonth, fetchAvailableSlots]);

    // useEffect(() => {
    //     console.log(slotObject)
    // }, [slotObject]);
    // useEffect(() => {
    //     console.log(selectedDate)
    // }, [selectedDate]);
    // useEffect(() => {
    //     console.log(selectedSlot)
    // }, [selectedSlot]);
    // useEffect(() => {
    //     console.log(selectedDates)
    //     console.log(selectedSlots)
    // }, [selectedDates]);
    // useEffect(() => {
    //     console.log(selectedSlots)
    // }, [selectedSlots]);

    useEffect(() => {
        if(!allowMultiple && allDay) {
            if(selectedDate !== false && selectedDate.availableSlots && selectedDate.availableSlots.length > 0) {
                setSelectedSlot(selectedDate.availableSlots[0]);
                setTimeout(() => {
                    setStep(step + 1);
                }, 100)
            }
        }
    }, [selectedDate]);


    return (
        <div className="inoovum-booking-app">
            <div className="w-full rounded-xl shadow-xl p-5">
                <div className="flex flex-row gap-5 relative">

                    {submitted ?
                        <Fragment>
                            <p>{properties.success}</p>
                        </Fragment>
                        :
                        <Fragment>
                            {submitting &&
                                <div className="absolute left-0 top-0 w-full h-full bg-[rgba(255,255,255,0.8)] flex flex-row items-center justify-center">
                                    <div>
                                        <Spinner/>
                                    </div>
                                </div>
                            }

                            {step === 1 &&
                                <Step title={properties.title1} description={properties.description1} handleSetStep={() => {
                                }}>
                                    {slotObjects && slotObjects.length > 0 &&
                                        <div className="grid grid-cols-2 gap-3">
                                            {slotObjects.map((slot: { identifier: string, name: string, allDay: boolean, allowMultiple: boolean }) =>
                                                <Button key={slot.identifier} variant="outline" onClick={() => {
                                                    resetValues();
                                                    setSlotObject(slot.identifier);
                                                    setAllDay(slot.allDay);
                                                    setAllowMultiple(slot.allowMultiple);
                                                    setStep(step + 1)
                                                }}>{slot.name}</Button>
                                            )}
                                        </div>
                                    }
                                </Step>
                            }

                            {slotObject && step === 2 &&
                                <Step title={properties.title2} description={properties.description2} setStep={setStep} step={step} buttonLabel={allowMultiple && ((selectedDate && selectedSlots && selectedSlots.length > 0 && !allDay) || (!allowMultiple && selectedDates.length > 0)) ? 'Weiter' : false} handleSetStep={allowMultiple && ((selectedDate && !allDay) || (selectedDates.length > 0)) ? () => setStep(step + 1) : false}>
                                    <div className="grid grid-cols-2 gap-3">
                                        <div>
                                            {availableSlots && availableSlots.length > 0 &&
                                                <Month currentYear={currentYear} setCurrentYear={setCurrentYear} currentMonth={currentMonth} setCurrentMonth={setCurrentMonth}
                                                       availableSlots={availableSlots} selectedDate={selectedDate} selectedDates={selectedDates} allDay={allDay}
                                                       setSelectedDate={setSelectedDate} setSelectedDates={(p: any) => handleSetSelectedDates(p)} allowMultiple={allowMultiple} selectedSlots={selectedSlots} setSelectedSlots={(p: any) => setSelectedSlots(p)}/>
                                            }
                                        </div>
                                        {(selectedDate || (selectedDates && selectedDates.length > 0)) && !allDay &&
                                            <div>
                                                <Time selectedDate={selectedDate} selectedSlot={selectedSlot} allDay={allDay} allowMultiple={allowMultiple} selectedSlots={selectedSlots} setSelectedSlots={(p: any) => setSelectedSlots(p)}
                                                      setSelectedSlot={(p: any) => {
                                                          setSelectedSlot(p);
                                                          setStep(step + 1)
                                                      }}/>
                                            </div>
                                        }
                                    </div>
                                </Step>
                            }

                            {(selectedSlot || (selectedDates && selectedDates.length > 0) || selectedDate) && step === 3 &&
                                <Step title={properties.title3} description={properties.description3} setStep={setStep} step={step} handleSetStep={() => {
                                }}>
                                    <RequestForm selectedDate={selectedDate} selectedSlot={selectedSlot}
                                                 selectedDates={selectedDates} selectedSlots={selectedSlots}
                                                 slotObject={slotObject} setSubmitting={setSubmitting}
                                                 setSubmitted={setSubmitted}
                                                 properties={properties}
                                    />
                                </Step>
                            }
                        </Fragment>
                    }

                </div>

            </div>
        </div>
    );
}

export default App;
